import { UserRoles, LocalStorageKeys} from '../Constants';
import { Reducer } from 'redux';

export const SET_ACTIVE_USER_ROLE = 'SET_ACTIVE_USER_ROLE';

export const setActiveUserRole = (activeRole: UserRoles) => ({
  type: SET_ACTIVE_USER_ROLE as typeof SET_ACTIVE_USER_ROLE,
  payload: { activeRole },
});

interface UserState {
  activeRole: UserRoles;
}

const roleFromLocalStorage = localStorage.getItem(LocalStorageKeys.role);

const initialState: UserState = {
  activeRole:   roleFromLocalStorage ? roleFromLocalStorage as UserRoles : UserRoles.Admin,
};

type UserAction = ReturnType<typeof setActiveUserRole>;

const userReducer: Reducer<UserState, UserAction> = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_USER_ROLE:
      localStorage.setItem(LocalStorageKeys.role, action.payload.activeRole);
      return {
        ...state,
        activeRole: action.payload.activeRole,
      };
    default:
      return state;
  }
};

export default userReducer;