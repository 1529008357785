import Axios from "./CustomAxios";

export interface SchoolData {
	name?: string;
	institutionNumber?: string;
	street?: string;
	city?: string;
	website?: string;
	description?: string;
	additionalPageTitle?: string;
	additionalPageText?: string;
	displayAdditionalPage?: boolean;
	printStartedAtScholl?: boolean;
	aboutSchoolHeading?: string;
	showMVH?: boolean;
	showEmneOpgave?: boolean;
	useCprNumbers?: boolean;
	useTimesFont?: boolean;
	italicStatements?: boolean;
	smallerDescriptionlineSpacing?: boolean;
	biggerStatementLineSpacing?: boolean;
	tableOfContentSizePtc?: number;
	roomForSignature?: boolean;
	useSmallerDescriptionFontSize?:boolean;
	showHFNiveauInHeadings?:boolean;
	showCourseDescriptions?:boolean;
	printA5Page?:boolean;
	displaySchoolInfo?:boolean;
}

export const GetSchoolData = async (): Promise<SchoolData> => {
	return (await Axios.get(`/rsvidnesbyrd/school/mySchool`)).data;
};

/**
 * If a field in SchoolData is missing, it will not be updated.
 */
export const UpdateSchoolData = async (data: SchoolData) => {
	return (await Axios.put(`/rsvidnesbyrd/school/update`, data)).data;
};


export const initialSchoolState: SchoolData= { 
	name: "", institutionNumber: "", 
	website: "", 
	street: "", 
	city: "",
	description: "" ,
	aboutSchoolHeading:"",
	printStartedAtScholl:true,
	showEmneOpgave:true,
	useCprNumbers:true,
	showMVH:true, 
	useTimesFont:false, 
	italicStatements:false, 
	smallerDescriptionlineSpacing:false, 
	biggerStatementLineSpacing:false, 
	tableOfContentSizePtc:100, 
	roomForSignature:true,
	useSmallerDescriptionFontSize:false, 
	showHFNiveauInHeadings: false, 
	showCourseDescriptions: true , 
	printA5Page : false,
	displaySchoolInfo : false};