import { Styles } from "react-select/src/styles";
import i18n from "./i18n";

export enum UserRoles {
	Admin = "admin",
	Teacher = "teacher",
	ProofReader = "proofReader",
}

//keep these values same as values in Constants class on server
export enum DescriptionStatus {
	Created = "created",
	ReadyForProofRead = "readyForProofread",
	ProofRead = "proofread",
	Done = "done",
}

//keep these values same as values in Constants class on server
// if you add a new value here, add it also in resources, for ex: "hfType.extended": "..."
export enum HFType {
	Basic = "basic",
	Extended = "extended", // default
}

//used in different components to denote size, for example for buttons
export enum Size {
	SM, //small
	MD, //medium
	LG, //large
}

export enum ManageModalAction {
	Edit,
	Create,
}

export const UserRolesSelectOptions = [
	{ value: UserRoles.Admin, label: i18n.t(UserRoles.Admin) },
	{ value: UserRoles.Teacher, label: i18n.t(UserRoles.Teacher) },
	{ value: UserRoles.ProofReader, label: i18n.t(UserRoles.ProofReader) },
];

export const ClassLevelSelectOptions = [
	{ value: 0, label: "0. klasse" },
	{ value: 1, label: "1. klasse" },
	{ value: 2, label: "2. klasse" },
	{ value: 3, label: "3. klasse" },
	{ value: 4, label: "4. klasse" },
	{ value: 5, label: "5. klasse" },
	{ value: 6, label: "6. klasse" },
	{ value: 7, label: "7. klasse" },
	{ value: 8, label: "8. klasse" },
	{ value: 9, label: "9. klasse" },
	{ value: 10, label: "10. klasse" },
	{ value: 11, label: "1. HF" },
	{ value: 12, label: "2. HF" },
];

export const CourseDurationSelectOptions = [
	{ label: `1 ${i18n.t("year")}`, value: 1 },
	{ label: `2 ${i18n.t("years")}`, value: 2 },
];

export const HFLettersSelectOptions = [
	{ label: "A", value: "A" },
	{ label: "B", value: "B" },
	{ label: "C", value: "C" },
	{ label: " ", value: " " },
];

export const ProofreaderStatusFilterOptions = [
	{
		value: DescriptionStatus.ReadyForProofRead,
		label: i18n.t(`descriptionStatus.${DescriptionStatus.ReadyForProofRead}`),
	},
	{
		value: DescriptionStatus.ProofRead,
		label: i18n.t(`descriptionStatus.${DescriptionStatus.ProofRead}`),
	},
];

export const TeacherStatusFilterOptions = [
	{
		value: DescriptionStatus.Created,
		label: i18n.t(`descriptionStatus.${DescriptionStatus.Created}`),
	},
	{
		value: DescriptionStatus.ReadyForProofRead,
		label: i18n.t(`descriptionStatus.${DescriptionStatus.ReadyForProofRead}`),
	},
	{
		value: DescriptionStatus.ProofRead,
		label: i18n.t(`descriptionStatus.${DescriptionStatus.ProofRead}`),
	},
	{
		value: DescriptionStatus.Done,
		label: i18n.t(`descriptionStatus.${DescriptionStatus.Done}`),
	},
];

export const apiUrl =
	window.location.host.includes("localhost") || window.location.host.includes("192.168.11.132")
		? window.location.host.includes("localhost")
			? "http://localhost:8080"
			: "http://192.168.11.132:8080"
		: "https://api.rssvid.dk";
export const clientUrl = window.location.protocol + "//" + window.location.host; //"http(s)://rssvid.dk" or "http://localhost:3000"

export const LocalStorageKeys = {
	currentUser: "currentUser",
	credentials: "credentials",
	role : "role",
};

// keep these values up to date with values from server.
// if you add a new value here, add it also in resources, for ex: "taskType.arsopgave":"..."
export enum TaskType {
	SSO = "sso", // SSO is for 12 classes (2HF)
	YearTask = "arsopgave", // YearTask is for 12 classes (2HF)
	Emneopgave = "emneopgaver", // Emneopgave is for 10th classes
}

export const HeaderRoleDropdownStyle: Partial<Styles> = {
	control: (base) => ({
		...base,
		minHeight: 24,
		fontSize: "0.95rem",
		border: 0,
		boxShadow: "none",
	}),
	dropdownIndicator: (base) => ({
		...base,
		padding: 2,
	}),
	clearIndicator: (base) => ({
		...base,
		padding: 2,
	}),
	multiValue: (base) => ({
		...base,
	}),
	valueContainer: (base) => ({
		...base,
		padding: "0px",
	}),
	input: (base) => ({
		...base,
		margin: 0,
		padding: 0,
	}),
	indicatorSeparator: (base) => ({ ...base, backgroundColor: "transparent" }),
	placeholder: (base) => ({ ...base, marginLeft: 0, marginRight: 0 }),
	singleValue: ({ maxWidth, position, top, transform, ...otherStyles }) => ({ ...otherStyles, marginLeft: 0, marginRight: 0 }),
	option: (base) => ({ ...base, fontSize: "0.95rem", padding: 4 }),
	menu: (base) => ({ ...base, width: 130 }),
};

export const PreviousTextVersionDropdownStyle: Partial<Styles> = {
	container: (base) => ({
		...base,
		display: "flex",
	}),
	control: (base) => ({
		...base,
		minHeight: 24,
		border: 0,
		boxShadow: "none",
		fontWeight: "bold",
		color: "black",
	}),
	dropdownIndicator: (base) => ({
		...base,
		padding: 2,
	}),
	clearIndicator: (base) => ({
		...base,
		padding: 2,
	}),
	multiValue: (base) => ({
		...base,
	}),
	valueContainer: (base) => ({
		...base,
		padding: "0px",
	}),
	input: (base) => ({
		...base,
		margin: 0,
		padding: 0,
	}),
	indicatorSeparator: (base) => ({ ...base, backgroundColor: "transparent" }),
	placeholder: (base) => ({ ...base, marginLeft: 0, marginRight: 0 }),
	singleValue: ({ maxWidth, position, top, transform, ...otherStyles }) => ({ ...otherStyles, marginLeft: 0, marginRight: 0 }),
	option: (base) => ({ ...base, fontSize: "0.95rem", padding: 4 }),
};
